import logo from './vbcontrol.png'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


export const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        const validationErrors = {};

        console.log(e);
        
        emailjs.sendForm('service_ktjui6o', 'template_539cd91', form.current, 'OUilPQciiowAdN6LS').then((result) => 
        {
            console.log(result.text);
            alert("Email met success verstuurd. We nemen zo spoedig contact met u op!");
        }, 
        (error) => 
        {
            console.log(error.text);
            alert("Email not sent due to \n"+ error);
        });
    }

    return (
        <span id="contact">
            <div class="vb-page">
                <div class="container vb-margin">
                    <div class="row justify-content-center">
                        <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-xs-8 vb-form-container">
                            <form ref={form} onSubmit={sendEmail}>
                                <div class="vb-title-container">
                                    <span class="vb-form-title">
                                        Contact
                                    </span>
                                </div>
                                <div class="vb-title-container">
                                    <p class="vb-form-subtitle">
                                        Als u het formulier heeft ingevuld zullen wij spoedig contact met u opnemen.<br></br><br></br>Alvast bedankt voor de belangstelling!
                                    </p>
                                </div>
                                <div class="vb-input-section">
                                    <label for="name" class="form-label">Naam</label>
                                    <input type="text" class="form-control" id="name" name="from_name"/>
                                </div>
                                <div class="vb-input-section">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" class="form-control" name="from_mail" id="email"/>
                                </div>
                                <div class="vb-input-section">
                                    <label for="message" class="form-label">Bericht</label>
                                    <textarea class="form-control vb-textarea" aria-label="With textarea" id="message" name="message"></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary vb-button-wide">Submit</button>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>
        </span>
        
    );
}
