import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import './css/main.css'
import './Footer.css';
import Img_1 from './image/mapsicon.png';
import Img_2 from './image/linkedin.png';
import Img_3 from './image/companyicon.png';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <span id="footer">
      {/* <div class="bottom"></div> */}
      <div class="vb-footer-box">
        <div class="container vb-table-footer">
          <div class="row">
            <div class="col-md-4 gy-4">
              <div class="d-flex">
                <a href="https://maps.app.goo.gl/MEwL9W7esxmukqHW8" target="_blank"><img class="vb-icon" src={Img_1}></img></a>
                <p>Draai 11 3311JJ Dordrecht</p>
              </div>    
            </div>
            <div class="col-md-4 gy-4">
              <div class="d-flex">
                <img class="vb-icon" src={Img_3}></img>
                <p>KVK: 92247539</p>
              </div>
            </div>
            <div class="col-md-4 gy-4">
              <div class="d-flex">
                <a href="https://www.linkedin.com/company/vb-control/" target="_blank"><img class="vb-icon" src={Img_2}></img></a>
                <p>Email: info@vbcontrol.com</p>
              </div>
            </div>     
          </div>
        </div>
      </div>
    </span>
    

  );
}

export default Footer;
