import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import { Contact } from "./Contact";
import { About } from "./About";


function App() {
  return <Routes>
    <Route exact path="/" element= { <Home /> } />
    <Route path="/home" element={ <Home /> } />
    <Route path="/contact" element={ <Contact /> } />
    <Route path="/about" element={ <About /> } />
    <Route path="/sitemap.xml" />
    <Route path="/robots.txt" />
  </Routes>
}

export default App;
