import 'bootstrap/dist/css/bootstrap.css';
import Video from "./vid3.mp4";
import { useRef } from 'react';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

// Images 
import Img_Background from "./image/home_background.svg";
import Img_1 from "./image/laptop.png";
import Img_2 from "./image/trendsoftware.png";
import Img_3 from "./image/technischeruimte.png";
import Img_4 from "./image/multimeter.png";
import Img_Demo_WKO from "./image/Demo_WKO.png";
// import Img_Demo_Verdeler from "./image/Demo_Verdeler.png";
import Img_Demo_LBK from "./image/Demo_LBK.png";
import Img_Demo_Gebouw from "./image/Demo_Gebouw.png";
import Img_Demo_Verdieping from "./image/Demo_Verdieping.png";
import Img_Demo_Verdieping_2 from "./image/Demo_Verdieping_2.png";
import Img_Bespaar from './image/simple/undraw_investing.svg';
import Img_Inzicht from './image/simple/undraw_data_trends.svg';
import Img_Join from './image/simple/undraw_join.svg';
import Img_Design from './image/simple/undraw_product_iteration.svg';
import Img_Installment from './image/simple/undraw_setup_wizard.svg'
import Img_Development from './image/simple/undraw_web_developer.svg';
import Img_Maintenance from './image/simple/undraw_air_support.svg';


function Home() {

  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 1;
  };

  return (
    <span id="span-full-lenght">
      <div class="vb-page" id="home">
        {/* Starting page */}
        <div class="container vb-home-title-box">
          <div class="row">
            <div class="col-lg-12">
              <div class="vb-abs-overlay">
                <div class="row vb-padding-mobile">
                    <div class="p-3 col-lg-6 col-sm-12 vb-home-video-overlay">
                      <h1 class="vb-home-title">Expert in Gebouw beheer systemen</h1>
                    </div>
                    <div class="p-3 col-lg-6 col-sm-12 vb-home-video-overlay">
                      <div class="row">
                        <div class="p-3 col-md-12">
                          <h3 class="vb-md-title"><span class="vb-md-title-emphasis">Betrouwbaarheid als speerpunt</span></h3>
                          <h4 class="vb-sm-title">Bewezen hardware & software IoT plaftorm Niagara Framework, doorgeteste regelkringen en extensieve kwaliteitswaarborging van engineering tot aan bedrijfstelling</h4>
                        </div>
                        <div class="p-3 col-md-12">
                          <h3 class="vb-md-title"><span class="vb-md-title-emphasis">GACS Conformiteit</span></h3>
                          <h5 class="vb-sm-title">Ons gebouw systeem ontwerp voldoet aan de Europese wetgevingen Utiliteitsbouw</h5>
                        </div>
                        <div class="p-3 col-md-12">
                          <h3 class="vb-md-title"><span class="vb-md-title-emphasis">Maximaal inzicht</span></h3>
                          <h4 class="vb-sm-title">Dashboarding, rapportages en moderne visualisatie van alle geïntegreerde systemen</h4>
                        </div>
                      </div>
                  </div>
                  <div class="vb-home-video-overlay"> 
                    <div class="row justify-content-center">
                      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-4">
                        <Link 
                          to="/contact" 
                          style={{ textDecoration: 'none' }}>  
                          <button id="about" type="submit" class="btn btn-primary vb-home-title-button">Kom in contact!</button>
                        </Link>              
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="vb-home-video">
                <video ref={videoRef} onCanPlay={() => setPlayBack()} class="vb-home-video" autoplay autoPlay loop muted playsInline webkit-playsInline>
                  <source src={Video} alt="Utiliteitsbouw" type="video/mp4"/>
                </video>
              </div> */}
              <div>
                  <img src={Img_Background} class="vb-home-background"></img>
              </div>
            </div>
          </div>
        </div>
        <div class="vb-home-content-contrast">
          <div class="container vb-home-content">
            <div class="row vb-padding-mobile">
              <div class="col-lg-12">
                  <h5 class="vb-about-subtitle-center">Waarom een gebouwbeheersysteem?</h5>
              </div>
            </div>
            <div class="row">
              <div class="p-4 col-lg-4 col-sm-12 vb-home-card-title">
                  <img src={Img_Bespaar} class="vb-img-card"></img>
                  <h2>1. Bespaar & voeg toe</h2>
                  <h4 class="vb-home-card-text">Bespaar op energiekosten door effectief om te gaan met primaire klimaatinstallaties, verlichting en aanwezigheidsafhankelijke ruimte klimatiseren, met waardeverhoging van het vastgoed tot gevolg.</h4>
              </div>
              <div class="p-4 col-lg-4 col-sm-12 vb-home-card-title">
                  <img src={Img_Inzicht} class="vb-img-card"></img>
                  <h2>2. Inzicht & Analyze</h2>
                  <h4 class="vb-home-card-text">Overzichtelijke dashboards om gebouwprestaties te meten en beoordelen, principe schemas om de installatie status te bepalen, beide compatibel op mobiel, tablet of computer.</h4>
              </div>
              <div class="p-4 col-lg-4 col-sm-12 vb-home-card-title">
                  <img src={Img_Join} class="vb-img-card"></img>
                  <h2>3. Service & Onderhoud</h2>
                  <h4 class="vb-home-card-text">Storingsmonitoring, preventieve onderhoudsmeldingen en overbruggings mogelijkheden om van afstand grip te krijgen op het functioneren van het gebouw en zo het comfort van de gebruikers te garanderen.</h4>
              </div>
            </div>
          </div>
        </div>
        {/* What we do section */}
        <div class="vb-content-normal">
          <div class="container vb-home-content">
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="vb-about-subtitle-center">Onze service</h5>
                </div>
              </div>
            <ul class="nav nav-pills mb-3 vb-tab-buttons" id="pills-tab" role="tablist">
              <li class="nav-item vb-tab-pill" role="presentation">
                <button class="nav-link nav-link-vb active" id="pills-design-tab" data-bs-toggle="pill" data-bs-target="#pills-design" type="button" role="tab" aria-controls="pills-design" aria-selected="true">1. Ontwerp</button>
              </li>
              <li class="nav-item vb-tab-pill" role="presentation">
                <button class="nav-link nav-link-vb" id="pills-software-tab" data-bs-toggle="pill" data-bs-target="#pills-software" type="button" role="tab" aria-controls="pills-software" aria-selected="false">2. Ontwikkeling</button>
              </li>
              <li class="nav-item vb-tab-pill" role="presentation">
                <button class="nav-link nav-link-vb" id="pills-commissioning-tab" data-bs-toggle="pill" data-bs-target="#pills-commissioning" type="button" role="tab" aria-controls="pills-commissioning" aria-selected="false">3. Installatie</button>
              </li>
              <li class="nav-item vb-tab-pill" role="presentation">
                <button class="nav-link nav-link-vb" id="pills-service-tab" data-bs-toggle="pill" data-bs-target="#pills-service" type="button" role="tab" aria-controls="pills-service" aria-selected="false">4. Onderhoud</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane show active vb-tab-pane vb-fade-in" id="pills-design" role="tabpanel" aria-labelledby="pills-design-tab">
                <div class="d-flex vb-tab-content">
                  <img class="vb-tab-image" src={Img_Design} alt="Het Ontwerp van Regeltechnische Omschrijvingen (RTO), ontwerpen van systeem topologie, IO lijsten van de regelkast, regelkastschema's"></img>
                  <div class="vb-tab-text">
                    <h1 class="vb-tab-text-title">We ontwerpen een systeem dat aansluit op jouw wensen</h1>
                    <p class="vb-tab-text-body">
                      Aan de hand van jouw wensen en onze ervaring ontwerpen we het ideale systeem dat aansluit op jouw gebouw. Het complete proces wordt gedocumenteerd met als resultaat:
                      <ul>
                        <li>Een regeltechnisch ontwerp</li>
                        <li>Een hardware & netwerk topologie</li>
                        <li>IO lijsten</li>
                        <li>Kasttekeningen</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane vb-fade-in" id="pills-software" role="tabpanel" aria-labelledby="pills-software-tab">
                <div class="d-flex vb-tab-content">
                  <img class="vb-tab-image" src={Img_Development} alt="Regeltechniek GBS software BMS systeem. SCADA en HMI. Inputs / Outputs. Koppelingen met BACnet, Modbus en diverse anderen. Dashboards en inzicht in HVAC installatie"></img>
                  <div class="vb-tab-text">
                    <article>
                      <h1 class="vb-tab-text-title">Automatisering, Integratie & Visualisatie</h1>
                      <p class="vb-tab-text-body">
                        Op basis van het ontwerp zal de regelkast en software ontwikkeld worden om jouw gebouw leven in te blazen!
                        <ul>
                          <li>SCADA</li>
                          <li>Dashboarding & Rapportages</li>
                          <li>Besturingssoftware HVAC installaties</li>
                          <li>Systeem Integratie</li>
                        </ul>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
              <div class="tab-pane vb-fade-in" id="pills-commissioning" role="tabpanel" aria-labelledby="pills-commissioning-tab">
                <div class="d-flex vb-tab-content">
                  <img class="vb-tab-image" src={Img_Installment} alt="In bedrijfstelling (IBS) van de regelsystemen, regelkast bouw, IO test. Projectmanagement. Functionele test. Deliverables"></img>
                  <div class="vb-tab-text">
                    <article>
                      <h1 class="vb-tab-text-title">Realisatie, inbedrijfstelling en oplevering</h1>
                      <p class="vb-tab-text-body">
                        Het ontwikkelde systeem wordt door VB Control in bedrijf gesteld, waarbij alles grondig functioneel en integraal wordt getest.
                        <ul>
                          <li>Kastombouw</li>
                          <li>Bekabeling</li>
                          <li>IO testen</li>
                          <li>In bedrijf stellen</li>
                          <li>Oplevering en overdracht</li>
                        </ul>
                      </p>
                    </article>
                  </div>
                </div>
              </div>
              <div class="tab-pane vb-fade-in" id="pills-service" role="tabpanel" aria-labelledby="pills-service-tab">
                <div class="d-flex vb-tab-content">
                  <img class="vb-tab-image" src={Img_Maintenance} alt="Service en onderhoud van GBS systemen. Storing zoeken in installaties. Persoonlijk contact met een bekwame engineer. Uitbreidingen regelinstallatie"></img>
                  <div class="vb-tab-text">
                      <article>
                        <h1 class="vb-tab-text-title">Service gerelateerde werkzaamheden</h1>
                        <p class="vb-tab-text-body">
                          Wij bieden advies en vervulling van onderhoudswerkzaamheden van het gebouwbeheersysteem om uw gebouw ten alle tijden optimaal te laten presteren!
                          <ul>
                            <li>Controle werking installatie</li>
                            <li>Optimalisaties</li>
                            <li>Software updates</li>
                            <li>Advies</li>
                            <li>Uitbreidingen</li>
                          </ul>
                        </p>
                      </article>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        {/* Demo solution section */}
        <div class="vb-home-content-contrast">
          <div class="container vb-home-content">
            <div class="row">
              <div class="col-lg-12">
                  <h5 class="vb-about-subtitle-center">Software voorbeelden:</h5>
              </div>
            </div>
            <div id="carouselExampleCaptions" class="carousel slide vb-home-carousel" data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src={Img_Demo_WKO} class="vb-img-fit" alt="WKO Warmte en Koude Opwekking, warmtepompen in combinatie met een open bron systeem wekt gasloos CV en GKW water op voor de betreffende faciliteit"/>
                </div>
                <div class="carousel-item">
                  <img src={Img_Demo_LBK} class="vb-img-fit" alt="LBK luchtbehandelingskast voor ventilatie van het gebouw"/>
                </div>
                <div class="carousel-item">
                  <img src={Img_Demo_Gebouw} class="vb-img-fit" alt="3D faciliteit weergave voor orientatie van de betreffende onderhoudspartij en gemakkelijk inzicht in de staat van de ruimtes"/>
                </div>
                <div class="carousel-item">
                  <img src={Img_Demo_Verdieping} class="vb-img-fit" alt="In een 3D weergave van de verdiepingen is te zien wat de status van de verlichting is"/>
                </div>
                <div class="carousel-item">
                  <img src={Img_Demo_Verdieping_2} class="vb-img-fit" alt="In een 3D weergave van de verdiepingen is te zien wat de temperatuur is van de ruimtes en meer informatie over naregelingen"/>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
            </div>
          </div>
        </div>
      </div>
    </span>

  );
}

export default Home;
