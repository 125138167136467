import React, { useState } from 'react';
import { Link } from "react-router-dom";

// Images
import Img_Placeholder from './image/placeholder.png';
import Img_PersonA from './image/personA.jpg';
import Icon_Mail from './image/mail.svg';
import Icon_LinkedIn from './image/linkedin_black.png';
import Img_ProgrammingPerson from './image/simple/undraw_programming.svg';
import Img_CallingPerson from './image/simple/undraw_phone_call.svg';
import Img_ConnectedPersons from './image/simple/undraw_connected_dark.svg';
import Img_Goals from './image/simple/undraw_shared_goals.svg';
import Img_Building from './image/simple/undraw_building.svg';
import Img_Dashboard from './image/simple/undraw_dashboard.svg';
import Img_Nick from './image/portret_nick.jpg';
import Img_Rick from './image/portret_rick.jpg';

import Img_Collage_1 from './image/gallery/installatie_1.jpg';
import Img_Collage_2 from './image/gallery/regelkast_1.jpg';
import Img_Collage_3 from './image/gallery/trip_1.jpg';
import Img_Collage_4 from './image/gallery/installatie_2.jpg';
import Img_Collage_5 from './image/gallery/testopstelling_1.jpg';
import Img_Collage_6 from './image/gallery/regelkast_2.jpg';
import Img_Collage_7 from './image/gallery/installatie_3.jpg';
import Img_Collage_8 from './image/gallery/testopstelling_2.jpg';
import Img_Collage_9 from './image/gallery/installatie_4.jpg';

export const About = () => {

    return(
        <span>
            <div class="container vb-about-content-top">
                <div class="row">
                    <div class="p-4 col-lg-6 col-sm-12">
                        {/* Textblock */}
                        <h5 class="vb-about-subtitle">OVER ONS</h5>
                        <h1 class="vb-about-title">VB Control levert het betrouwbare gebouw beheer systeem dat jij nodig hebt</h1>
                        <p class="vb-about-text">
                            VB Control in 2023 opgericht met als doel de gebouw automatiseringssystemen betrouwbaar en inzichtelijk te maken. 
                            <br/>Met onze kennis van HVAC, elektra en software bieden wij u een betrouwbare partner in gebouw automatisering.
                            Wij staan u persoonlijk te woord bij de ontwikkeling van het automatiseringssysteem en gaan in op uw wensen en vragen.
                        </p>
                        <div class="vb-general-margintop50">
                            <Link to="/contact" style={{ textDecoration: 'none' }}>  
                                <button type="submit" class="btn btn-primary vb-button">Kom in contact!</button>
                            </Link>
                            {/* <button type="submit" class="btn vb-button">Lees meer</button> */}
                        </div>
                    </div>
                    <div class="p-4 col-lg-6 col-sm-12">
                        {/* Image */}
                        <img src={Img_ConnectedPersons} class="vb-about-title-image"></img>
                    </div>
                </div>
            </div>
            <div class="vb-about-content-contrast">  
                <div class="container vb-about-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="vb-about-subtitle-center">VISIE</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p-4 col-lg-6 col-sm-12">
                        {/* Image */}
                        <img src={Img_Goals} class="vb-about-title-image"></img>
                        </div>
                        <div class="p-4 col-lg-6 col-sm-12">
                            {/* Uitleg */}
                            <p class="vb-about-text">
                                Het automatiseren en optimaliseren van gebouwen zit in ons bloed. Wij zijn er van overtuigd dat de software die VB control heeft ontwikkeld uw gebouw gebonden installaties tot een hoger niveau tilt op het gebied van:
                                <ul>
                                    <li>Betrouwbaarheid</li>
                                    <li>Inzichtelijkheid</li>
                                    <li>Energie efficiëntie</li>
                                    <li>Comfort</li>
                                </ul>
                            </p>
                        </div>
                        {/* <hr></hr> */}
                    </div>
                    {/* <div class="row">
                        <div class="p-4 col-lg-4 col-sm-12">
                            <img src={Img_ProgrammingPerson} class="vb-img-card"></img>
                            <h4 class="vb-about-text">GACS conform gebouwbeheersysteem gebasseerd op het Niagara Framework platform, waar betrouwbaarheid op prioriteit 1 staat</h4>
                        </div>
                        <div class="p-4 col-lg-4 col-sm-12">
                            <img src={Img_Building} class="vb-img-card"></img>
                            <h4 class="vb-about-text">GACS conform gebouwbeheersysteem gebasseerd op het Niagara Framework platform, waar betrouwbaarheid op prioriteit 1 staat</h4>
                        </div>
                        <div class="p-4 col-lg-4 col-sm-12">
                            <img src={Img_Dashboard} class="vb-img-card"></img>
                            <h4 class="vb-about-text">Het Niagara Framework dat we gebruiken is een platform qua mogelijkheden veel verder is dan dergelijke systemen als Priva in de utiliteitssector</h4>
                        </div>
                    </div> */}
                </div>
            </div>
            <div class="container vb-about-content">
                <div class="row">
                    <div class="col-lg-12">
                        <h5 class="vb-about-subtitle-center">HET TEAM</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="p-4 col-lg-6 col-sm-12">
                        {/* Rick */}
                        <div class="vb-about-bio-box">
                            {/* Image */}
                            <img src={Img_Rick} class="vb-img-radius-300"></img>
                            <h3 class="vb-about-bio-name">Rick Vervelde</h3>
                            {/* LinkedIn Icon */}
                            {/* Mail adres koppeling */}
                            <span class="vb-about-icon-box">
                                <a href= "mailto: rick.vervelde@vbcontrol.com">
                                    <img src={Icon_Mail} class="vb-about-icon"></img>
                                </a>
                                <a href="https://www.linkedin.com/in/rick-vervelde-017729179/" target='_blank'>
                                    <img src={Icon_LinkedIn} class="vb-about-icon"></img>
                                </a>
                            </span>

                        </div>
                    </div>
                    <div class="p-4 col-lg-6 col-sm-12">
                        {/* Nick */}
                        <div class="vb-about-bio-box">
                            {/* Image */}
                            <img src={Img_Nick} class="vb-img-radius-300"></img>
                            <h3 class="vb-about-bio-name">Nick Burggraaff</h3>
                            {/* LinkedIn Icon */}
                            {/* Mail adres koppeling */}
                            <span class="vb-about-icon-box">
                                <a href="mailto: nick.burggraaff@vbcontrol.com">
                                    <img src={Icon_Mail} class="vb-about-icon"></img>
                                </a>
                                <a href="https://www.linkedin.com/in/nickburggraaff/" target='_blank'>
                                    <img src={Icon_LinkedIn} class="vb-about-icon"></img>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="vb-about-content-contrast">
                <div class="container vb-about-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <h5 class="vb-about-subtitle-center">Wat gebeurd er in onze wereld</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_1} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_2} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_3} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_4} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_5} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_6} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_7} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_8} class="vb-about-image-card-image"></img>
                        </div>
                        <div class="p-4 col-lg-4 col-md-6 col-sm-12 vb-about-image-card">
                            <img src={Img_Collage_9} class="vb-about-image-card-image"></img>
                        </div>
                    </div>
                </div>
            </div> */}
        </span>
    )
}